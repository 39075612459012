const VersionSticker = () => {
  return (
    <div id="version-sticker">
      <div className="container">
        1.0.0 Beta
      </div>
    </div>
  )
}

export default VersionSticker
